/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { ByteString } from './byte-string';
// May contain unused imports in some cases
// @ts-ignore
import { Descriptor } from './descriptor';
// May contain unused imports in some cases
// @ts-ignore
import { EntityDataSourceConfig } from './entity-data-source-config';
// May contain unused imports in some cases
// @ts-ignore
import { EntityDataSourceConfigOrBuilder } from './entity-data-source-config-or-builder';
// May contain unused imports in some cases
// @ts-ignore
import { UnknownFieldSet } from './unknown-field-set';

/**
 * 
 * @export
 * @interface EntityData
 */
export interface EntityData {
    /**
     * 
     * @type {UnknownFieldSet}
     * @memberof EntityData
     */
    'unknownFields'?: UnknownFieldSet;
    /**
     * 
     * @type {string}
     * @memberof EntityData
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityData
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityData
     */
    'path'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityData
     */
    'encoding'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityData
     */
    'separator'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityData
     */
    'header'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EntityData
     */
    'initialized'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EntityData
     */
    'sourceType'?: EntityDataSourceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EntityData
     */
    'schemaPath'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityData
     */
    'executedCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityData
     */
    'metaString'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof EntityData
     * @deprecated
     */
    'dataTypes'?: { [key: string]: string; };
    /**
     * 
     * @type {EntityDataSourceConfig}
     * @memberof EntityData
     */
    'dataSourceConfig'?: EntityDataSourceConfig;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof EntityData
     */
    'ontologiesMap'?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof EntityData
     */
    'dataTypesMap'?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof EntityData
     */
    'serializedSize'?: number;
    /**
     * 
     * @type {object}
     * @memberof EntityData
     */
    'parserForType'?: object;
    /**
     * 
     * @type {EntityData}
     * @memberof EntityData
     */
    'defaultInstanceForType'?: EntityData;
    /**
     * 
     * @type {ByteString}
     * @memberof EntityData
     */
    'headerBytes'?: ByteString;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof EntityData
     */
    'dataTypesValueMap'?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof EntityData
     */
    'ontologiesValueMap'?: { [key: string]: number; };
    /**
     * 
     * @type {ByteString}
     * @memberof EntityData
     */
    'nameBytes'?: ByteString;
    /**
     * 
     * @type {ByteString}
     * @memberof EntityData
     */
    'typeBytes'?: ByteString;
    /**
     * 
     * @type {ByteString}
     * @memberof EntityData
     */
    'pathBytes'?: ByteString;
    /**
     * 
     * @type {ByteString}
     * @memberof EntityData
     */
    'schemaPathBytes'?: ByteString;
    /**
     * 
     * @type {ByteString}
     * @memberof EntityData
     */
    'encodingBytes'?: ByteString;
    /**
     * 
     * @type {ByteString}
     * @memberof EntityData
     */
    'separatorBytes'?: ByteString;
    /**
     * 
     * @type {number}
     * @memberof EntityData
     */
    'dataTypesCount'?: number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof EntityData
     * @deprecated
     */
    'dataTypesValue'?: { [key: string]: number; };
    /**
     * 
     * @type {number}
     * @memberof EntityData
     */
    'ontologiesCount'?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof EntityData
     * @deprecated
     */
    'ontologies'?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof EntityData
     * @deprecated
     */
    'ontologiesValue'?: { [key: string]: number; };
    /**
     * 
     * @type {ByteString}
     * @memberof EntityData
     */
    'metaStringBytes'?: ByteString;
    /**
     * 
     * @type {ByteString}
     * @memberof EntityData
     */
    'executedCodeBytes'?: ByteString;
    /**
     * 
     * @type {number}
     * @memberof EntityData
     */
    'sourceTypeValue'?: number;
    /**
     * 
     * @type {EntityDataSourceConfigOrBuilder}
     * @memberof EntityData
     */
    'dataSourceConfigOrBuilder'?: EntityDataSourceConfigOrBuilder;
    /**
     * 
     * @type {string}
     * @memberof EntityData
     */
    'initializationErrorString'?: string;
    /**
     * 
     * @type {Descriptor}
     * @memberof EntityData
     */
    'descriptorForType'?: Descriptor;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof EntityData
     */
    'allFields'?: { [key: string]: object; };
    /**
     * 
     * @type {number}
     * @memberof EntityData
     */
    'memoizedSerializedSize'?: number;
}

export const EntityDataSourceTypeEnum = {
    File: 'FILE',
    Database: 'DATABASE',
    Unrecognized: 'UNRECOGNIZED'
} as const;

export type EntityDataSourceTypeEnum = typeof EntityDataSourceTypeEnum[keyof typeof EntityDataSourceTypeEnum];
export const EntityDataDataTypesEnum = {
    Long: 'LONG',
    Float: 'FLOAT',
    String: 'STRING',
    Timestamp: 'TIMESTAMP',
    Boolean: 'BOOLEAN',
    Any: 'ANY',
    Double: 'DOUBLE',
    Unrecognized: 'UNRECOGNIZED'
} as const;

export type EntityDataDataTypesEnum = typeof EntityDataDataTypesEnum[keyof typeof EntityDataDataTypesEnum];
export const EntityDataOntologiesMapEnum = {
    Unknown: 'UNKNOWN',
    Address: 'ADDRESS',
    Ban: 'BAN',
    CreditCard: 'CREDIT_CARD',
    EmailAddress: 'EMAIL_ADDRESS',
    Uuid: 'UUID',
    HashOrKey: 'HASH_OR_KEY',
    Ipv4: 'IPV4',
    Ipv6: 'IPV6',
    MacAddress: 'MAC_ADDRESS',
    Person: 'PERSON',
    PhoneNumber: 'PHONE_NUMBER',
    Ssn: 'SSN',
    Url: 'URL',
    UsState: 'US_STATE',
    DriversLicense: 'DRIVERS_LICENSE',
    Date: 'DATE',
    Time: 'TIME',
    Datetime: 'DATETIME',
    Integer: 'INTEGER',
    Decimal: 'DECIMAL',
    Quantity: 'QUANTITY',
    Ordinal: 'ORDINAL',
    Unrecognized: 'UNRECOGNIZED'
} as const;

export type EntityDataOntologiesMapEnum = typeof EntityDataOntologiesMapEnum[keyof typeof EntityDataOntologiesMapEnum];
export const EntityDataDataTypesMapEnum = {
    Long: 'LONG',
    Float: 'FLOAT',
    String: 'STRING',
    Timestamp: 'TIMESTAMP',
    Boolean: 'BOOLEAN',
    Any: 'ANY',
    Double: 'DOUBLE',
    Unrecognized: 'UNRECOGNIZED'
} as const;

export type EntityDataDataTypesMapEnum = typeof EntityDataDataTypesMapEnum[keyof typeof EntityDataDataTypesMapEnum];
export const EntityDataOntologiesEnum = {
    Unknown: 'UNKNOWN',
    Address: 'ADDRESS',
    Ban: 'BAN',
    CreditCard: 'CREDIT_CARD',
    EmailAddress: 'EMAIL_ADDRESS',
    Uuid: 'UUID',
    HashOrKey: 'HASH_OR_KEY',
    Ipv4: 'IPV4',
    Ipv6: 'IPV6',
    MacAddress: 'MAC_ADDRESS',
    Person: 'PERSON',
    PhoneNumber: 'PHONE_NUMBER',
    Ssn: 'SSN',
    Url: 'URL',
    UsState: 'US_STATE',
    DriversLicense: 'DRIVERS_LICENSE',
    Date: 'DATE',
    Time: 'TIME',
    Datetime: 'DATETIME',
    Integer: 'INTEGER',
    Decimal: 'DECIMAL',
    Quantity: 'QUANTITY',
    Ordinal: 'ORDINAL',
    Unrecognized: 'UNRECOGNIZED'
} as const;

export type EntityDataOntologiesEnum = typeof EntityDataOntologiesEnum[keyof typeof EntityDataOntologiesEnum];


