/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { ByteString } from './byte-string';
// May contain unused imports in some cases
// @ts-ignore
import { Descriptor } from './descriptor';
// May contain unused imports in some cases
// @ts-ignore
import { UnknownFieldSet } from './unknown-field-set';

/**
 * 
 * @export
 * @interface NotebookError
 */
export interface NotebookError {
    /**
     * 
     * @type {UnknownFieldSet}
     * @memberof NotebookError
     */
    'unknownFields'?: UnknownFieldSet;
    /**
     * 
     * @type {string}
     * @memberof NotebookError
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof NotebookError
     */
    'errorTypeValue'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NotebookError
     */
    'initialized'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NotebookError
     */
    'errorType'?: NotebookErrorErrorTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof NotebookError
     */
    'serializedSize'?: number;
    /**
     * 
     * @type {object}
     * @memberof NotebookError
     */
    'parserForType'?: object;
    /**
     * 
     * @type {NotebookError}
     * @memberof NotebookError
     */
    'defaultInstanceForType'?: NotebookError;
    /**
     * 
     * @type {ByteString}
     * @memberof NotebookError
     */
    'messageBytes'?: ByteString;
    /**
     * 
     * @type {string}
     * @memberof NotebookError
     */
    'initializationErrorString'?: string;
    /**
     * 
     * @type {Descriptor}
     * @memberof NotebookError
     */
    'descriptorForType'?: Descriptor;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof NotebookError
     */
    'allFields'?: { [key: string]: object; };
    /**
     * 
     * @type {number}
     * @memberof NotebookError
     */
    'memoizedSerializedSize'?: number;
}

export const NotebookErrorErrorTypeEnum = {
    FileNotFound: 'FILE_NOT_FOUND',
    Unexpected: 'UNEXPECTED',
    Unrecognized: 'UNRECOGNIZED'
} as const;

export type NotebookErrorErrorTypeEnum = typeof NotebookErrorErrorTypeEnum[keyof typeof NotebookErrorErrorTypeEnum];


