import React, { useEffect } from "react";
import clsx from "clsx";

import _ from "lodash";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
  makeStyles
} from "@material-ui/core";
import InfoOutlined from "@material-ui/icons/InfoOutlined";

import EnvironmentTypeConfig from "src/pages/private/Environments/components/EnvironmentTypeConfig";
import Text from "components/Widget/Text";
import styles from "src/pages/private/Environments/Environments.module.scss";
import useGetEnvironments from "../../../../../../hooks/useGetEnvironment";
import { EnvDto } from "openapi/Models";
import { Environment } from "src/pages/private/Environments/Environments";
import { envMetadataFind } from "pages/Projects/helpers/projects.helpers";
import { statusValues } from "src/pages/private/Environments/components/StatusBar/StatusBar";
import { useGetEnvironmentTypes } from "src/hooks/api/environments/useGetEnvironmentTypes";
import { useStyles as useEnvStyles } from "src/pages/Projects/ProjectSettings/styling";

const useStyles = makeStyles(() => ({
  envTypeSpan: {
    fontSize: 12,
    color: "#838383"
  },
  flexEnd: {
    display: "flex",
    alignItems: "center",
    gap: "10px"
  }
}));

interface IProps {
  value: string;
  tooltipInfoMsg?: string;
  onChange: (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => void;
  shouldSetDefaultValue?: boolean;
  name: string;
  label: string;
  onEnvChange?: (environment: Environment) => void;
}

const SelectEnvironment: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const { envMenuItem, roundEnvStatus } = useEnvStyles();

  const envTypesResult = useGetEnvironmentTypes();
  const { data } = envTypesResult;

  const setDefault = (envs: EnvDto[]) => {
    if (!props.value && props.shouldSetDefaultValue) {
      const defaultId = _.find(envs, { name: "DEFAULT" })?.id;
      if (defaultId) {
        props.onChange({
          target: { value: defaultId, name: props.name }
        } as React.ChangeEvent<HTMLInputElement>);
      }
    }
  };
  const envResults = useGetEnvironments({
    onSuccess: (data) => {
      const environment = _.find(data, { id: props.value });

      setDefault(data);
      if (environment) {
        props.onEnvChange?.(environment as Environment);
      }
    },
    refetchOnMount: true
  });

  useEffect(() => {
    if (envResults.data) {
      setDefault(envResults.data);
    }
  }, []);

  const handleChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    props.onChange(e);
    const env = _.find(envResults.data, { id: e.target.value });
    if (env) {
      props.onEnvChange?.(env as Environment);
    }
  };

  return (
    <Grid className={classes.flexEnd}>
      <FormControl variant="outlined" fullWidth>
        <InputLabel shrink htmlFor={props.name}>
          Environment *
        </InputLabel>
        <Select
          label={props.label}
          required
          fullWidth
          defaultValue=""
          id={props.name}
          data-testid={props.name}
          name={props.name}
          value={props.value}
          onChange={handleChange}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            getContentAnchorEl: null
          }}
          input={<OutlinedInput notched label="Environment *" />}
          displayEmpty>
          {envResults.data?.map((env: any) => {
            const { name: type } = envMetadataFind(data ?? [], env) || {};
            return (
              <MenuItem key={env.id} value={env.id}>
                <Box className={envMenuItem}>
                  <Grid container direction="column">
                    <Text value={env.name} />
                    <span className={classes.envTypeSpan}>
                      <EnvironmentTypeConfig
                        envType={type}
                        cores={env?.cores}
                        memInMbs={env?.memInMbs}
                        diskInGbs={env?.diskInGbs}
                      />
                    </span>
                  </Grid>
                  <div
                    className={clsx(
                      roundEnvStatus,
                      styles.envStatus,
                      styles[env?.launchStatus?.trim()?.toLowerCase()]
                    )}>
                    {statusValues[env?.launchStatus?.trim()?.toLowerCase()]?.text ||
                      env?.launchStatus ||
                      "Unknown"}
                  </div>
                </Box>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {props.tooltipInfoMsg && (
        <Tooltip title={props.tooltipInfoMsg}>
          <InfoOutlined fontSize="small" />
        </Tooltip>
      )}
    </Grid>
  );
};

export default SelectEnvironment;
