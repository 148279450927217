import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { Button, CircularProgress, Tooltip } from "@material-ui/core";
import { generatePath, useNavigate, useParams } from "react-router-dom";

// Components
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import SubTopNavBarBreadcrumbs from "./SubTopNavBarBreadcrumbs";

import { ReplayOutlined } from "@material-ui/icons";

import { useProjectContext } from "src/pages/private/ProjectsModule/context/useProjectContext";
import { Recipe } from "src/types";
import usePublishTemplate from "src/hooks/api/transforms/usePublishTemplate";
import { first, get } from "lodash";
import { handleResponse } from "src/utils/apiService";
import { WebPaths } from "src/routing/routes";
import useResetNotebook from "src/hooks/api/transforms/useResetNotebook";
import { RevertChangesConfirmModal } from "../AutoMLNotebookContainer/RevertChangesConfirmModal";
import { useQueryClient, UseQueryResult } from "@tanstack/react-query";
import { QUERY_KEY_RECIPE } from "src/hooks/api/transforms/useGetRecipe";
// import useIsStagedTemplate from "src/hooks/api/transforms/useIsStagedTemplate";
import { ChangeTemplateResponseDto } from "openapi/Models";

interface IProps {
  recipe: Recipe | undefined;
  templateUrlResult: UseQueryResult<ChangeTemplateResponseDto, Error>;
  iframeLoading: boolean;
  setKey: Dispatch<SetStateAction<string>>;
  setIframeLoading: Dispatch<SetStateAction<boolean>>;
}

export const AutoMLNotebookHeader: React.FC<IProps> = (props) => {
  const queryClient = useQueryClient();
  const {
    recipe,
    templateUrlResult: { isFetching, isLoading },
    iframeLoading,
    setKey,
    setIframeLoading
  } = props;
  const recipeRunconfigId = get(first(recipe?.runConfigs), "id");
  const { scenarioId, projectId } = useParams();
  const publishTemplate = usePublishTemplate();
  const resetNotebook = useResetNotebook();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  // const stagingDetails = useIsStagedTemplate(recipeRunconfigId, { refetchOnMount: true });

  const canGoback = useMemo(() => history.state && history.state.idx !== 0, [location]);

  const handlePublish = () => {
    if (recipeRunconfigId) {
      publishTemplate.mutate(
        { runConfigId: recipeRunconfigId },
        {
          onSuccess: (data) => {
            queryClient.removeQueries([QUERY_KEY_RECIPE]);
            if (data.success) {
              if (canGoback) {
                navigate(-1);
              } else {
                if (!!project?.id && !!scenarioId && !!recipe?.id)
                  navigate(
                    generatePath(WebPaths.AutoMLRecipeContainer, {
                      projectId: project?.id,
                      scenarioId,
                      groupId: recipe?.id
                    }),
                    { replace: true }
                  );
              }
              handleResponse({ successMessage: "Recipe Code is Edited in Notebook" });
            } else {
              handleResponse({ errorMessage: data.error.message ?? "something went wrong!!" });
            }
          }
        }
      );
    }
  };

  const handleCancel = () => {
    if (canGoback) {
      navigate(-1);
    } else {
      if (!!project?.id && !!scenarioId && !!recipe?.id)
        navigate(
          generatePath(WebPaths.AutoMLRecipeContainer, {
            projectId: project?.id,
            scenarioId,
            groupId: recipe?.id
          }),
          { replace: true }
        );
    }
  };

  const handleReset = () => {
    if (recipeRunconfigId) {
      resetNotebook.mutate(
        { runConfigId: recipeRunconfigId },
        {
          onSuccess: (data) => {
            if (data.success) {
              setOpen(false);
              setKey(Date.now().toString());
              setIframeLoading(true);
            } else {
              handleResponse({ errorMessage: data.error.message ?? "something went wrong!!" });
            }
          }
        }
      );
    }
  };

  const { project } = useProjectContext() || {};

  return (
    <>
      <SubTopNavBarWrapper
        subTopNavBarLeftSection={{
          backNavPath: `/projects/${projectId}/scenario/${scenarioId}/add-auto-ml-recipe/${recipe?.id}`,
          component: (
            <SubTopNavBarBreadcrumbs
              project={project}
              recipeName={recipe?.displayName ?? recipe?.name}
              scenarioId={scenarioId}
              groupId={recipe?.id}
            />
          )
        }}
        subTopNavBarRightSection={{
          component: (
            <>
              {/* {stagingDetails?.data?.templateStage === "DRAFT" && (
                <Tooltip title='There are changes saved in the notebook that have not yet been applied to the recipe. Please use "Save Back to Recipe" for the changes to take effect.'>
                  <InfoOutlined color="error" />
                </Tooltip>
              )} */}
              {!(isFetching || isLoading || iframeLoading) && (
                <Tooltip title="Reverts changes made to the notebook that haven't been saved back to the recipe yet.">
                  <Button
                    size="small"
                    color="primary"
                    startIcon={<ReplayOutlined />}
                    onClick={() => setOpen(true)}>
                    Revert
                  </Button>
                </Tooltip>
              )}
              <Button size="small" color="primary" onClick={handleCancel}>
                Cancel
              </Button>

              {!(isFetching || isLoading || iframeLoading) && (
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={handlePublish}
                  disabled={publishTemplate.isLoading || !recipeRunconfigId}
                  startIcon={publishTemplate.isLoading ? <CircularProgress size={20} /> : null}>
                  Save back to Recipe
                </Button>
              )}
              <RevertChangesConfirmModal
                loading={resetNotebook.isLoading}
                open={open}
                handleClose={() => {
                  setOpen(false);
                }}
                handleSubmit={handleReset}
              />
            </>
          )
        }}
      />
    </>
  );
};
