/* eslint-disable no-unused-vars */
import { useQuery } from "@tanstack/react-query";

import { getAPIWithRethrow } from "src/utils/apiService";
import { addTransformGroupWithRethrow } from "src/api";
import { ArtifactMini, Entity, EntityTypeEnum, ModelMini, Recipe } from "src/types";

type Output = {
  group: Recipe;
};
export const useCreateRecipe = ({
  inputEntities,
  projectId,
  recipeId,
  onSuccess,
  entity,
  conditions,
  code,
  selectedArtifacts,
  selectedModels,
  newAskAIFlow = false
}: {
  inputEntities: Array<Entity>;

  projectId?: string;
  recipeId: string | undefined;
  entity: Entity | undefined;
  conditions: $TSFixMe;
  code: string;
  selectedArtifacts: ArtifactMini[];
  selectedModels: ModelMini[];
  onSuccess: ({ group }: Output) => void;
  newAskAIFlow?: boolean;
}) => {
  return useQuery<Output>({
    queryKey: ["project", projectId, "allRecipes"],
    queryFn: async () => {
      if (!projectId) {
        throw "Project id is absent";
      }
      const groups: Array<Recipe> = await getAPIWithRethrow(
        `/v2/dfs-run-config-groups?projectId=${projectId}`
      );
      const entityName = entity?.name;
      const allRecipeNames = groups.reduce((acc: Array<string>, item) => {
        return [...acc, item.name?.toLowerCase(), item.displayName?.toLowerCase()];
      }, []);
      let maxRecipeNameLength = 64;
      let maxLength = 64 - " Recipe".length;
      let updatedEntityName =
        entityName && entityName?.length > maxLength
          ? entityName.substring(0, maxLength)
          : entityName;
      let updatedRecipeName = updatedEntityName ? `${updatedEntityName} Recipe` : `Recipe`;
      if (allRecipeNames?.length) {
        let i = 2;
        while (allRecipeNames?.includes(updatedRecipeName?.toLowerCase())) {
          updatedRecipeName = updatedEntityName
            ? `${updatedEntityName} Recipe ${i}`
            : `Recipe ${i}`;
          if (updatedRecipeName.length > maxRecipeNameLength) {
            const stripLength = updatedRecipeName.length - maxRecipeNameLength;
            updatedEntityName = updatedEntityName?.substring(
              0,
              updatedEntityName.length - stripLength
            );
            updatedRecipeName = updatedEntityName
              ? `${updatedEntityName} Recipe ${i}`
              : `Recipe ${i}`;
          }
          i = i + 1;
        }
      }

      const newArtifacts =
        selectedArtifacts?.map((artifact: $TSFixMe) => ({
          name: artifact.name,
          type: EntityTypeEnum.ARTIFACT
        })) || [];
      const newModels =
        selectedModels?.map((model: $TSFixMe) => ({
          name: model.name,
          type: EntityTypeEnum.MODEL
        })) || [];

      const transformGroupPayload = {
        name: updatedRecipeName,
        displayName: updatedRecipeName,
        parents: [
          ...inputEntities?.map((entity) => ({ id: entity.id, type: "ENTITY" })),
          ...newArtifacts,
          ...newModels
        ],
        condition: {
          expression: conditions
        },
        recipeType: "CODE",
        newAskAIFlow
      };
      const createdGroup = await addTransformGroupWithRethrow(transformGroupPayload, projectId);
      const group = Array.isArray(createdGroup) ? createdGroup[0] : createdGroup;

      return { group };
    },
    onSuccess,
    enabled: !recipeId && inputEntities.length > 0 && !code,
    cacheTime: 0
  });
};
