import React from "react";
import shallow from "zustand/shallow";
import { Grid, Box, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { OverflowTooltip } from "src/components";
import PasswordResetModal from "./PasswordResetModal";
import useAuthStore from "src/stores/auth.store";
import { handleResponse } from "src/utils/apiService";
import { postAPIWithRethrow } from "src/utils/apiService";

const useStyles = makeStyles((theme) => ({
  subTitle: {
    fontSize: "14px",
    color: "#000000",
    opacity: 0.5
  },
  userAvatar: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: "50%",
    color: theme.palette.primary.main,
    width: "48px",
    height: "42px"
  },
  flexWrap: {
    gap: "20px",
    flexWrap: "nowrap"
  }
}));
const UserData = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [userDetailsContainerWidth, setUserDetailsContainerWidth] = React.useState<
    number | undefined
  >(100);

  const userDetailsContainerRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    setUserDetailsContainerWidth(() => userDetailsContainerRef.current?.clientWidth);
  }, [userDetailsContainerRef.current?.clientWidth]);

  const handleOpenModal = React.useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleCloseModal = React.useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const styles = useStyles();
  const [userEmail, askAiEnabled] = useAuthStore(
    React.useCallback((state: any) => [state.userEmail, state.askAiEnabled], []),
    shallow
  );

  const handleReset = async () => {
    try {
      setDisabled(true);
      await postAPIWithRethrow("/password/reset-link", {
        email: userEmail,
        host: window.location.origin
      });
      handleResponse({ successMessage: "Reset Password email sent succesfully" });
    } catch (error: $TSFixMe) {
      console.error(error);
    } finally {
      handleCloseModal();
      setDisabled(false);
    }
  };

  return (
    <Grid container direction="row" className={styles.flexWrap}>
      {userEmail && (
        <Grid
          container
          className={styles.userAvatar}
          justifyContent="center"
          alignItems="center"
          test-id="user-profile-user-icon">
          <Typography variant="h6">{userEmail.charAt(0)?.toUpperCase()}</Typography>
        </Grid>
      )}
      <PasswordResetModal
        open={isModalOpen}
        disabled={disabled}
        onClose={handleCloseModal}
        onReset={handleReset}
      />
      <Grid container direction="column" alignItems="flex-start" ref={userDetailsContainerRef}>
        <Box pb={2}>
          <Typography className={styles.subTitle}>Details</Typography>
          <Typography color="textPrimary" test-id="user-profile-user-email">
            <OverflowTooltip
              style={{
                maxWidth: userDetailsContainerWidth,
                whiteSpace: "nowrap"
              }}
              value={userEmail}
            />
          </Typography>
        </Box>
        {!!askAiEnabled && (
          <Button variant="outlined" color="primary" onClick={() => handleOpenModal()}>
            Reset Password
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default UserData;
