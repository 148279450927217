/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { IntermediateDataset } from './intermediate-dataset';

/**
 * 
 * @export
 * @interface ThreadTargetInput
 */
export interface ThreadTargetInput {
    /**
     * 
     * @type {string}
     * @memberof ThreadTargetInput
     */
    'inputEntityType'?: ThreadTargetInputInputEntityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ThreadTargetInput
     */
    'entityId'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ThreadTargetInput
     */
    'entitySizesAvailable'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof ThreadTargetInput
     */
    'entityName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ThreadTargetInput
     */
    'entityDisplayName'?: string;
    /**
     * 
     * @type {IntermediateDataset}
     * @memberof ThreadTargetInput
     */
    'intermediateDataset'?: IntermediateDataset;
    /**
     * 
     * @type {string}
     * @memberof ThreadTargetInput
     */
    'chartId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ThreadTargetInput
     */
    'chartName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ThreadTargetInput
     */
    'filePath'?: string;
    /**
     * 
     * @type {string}
     * @memberof ThreadTargetInput
     */
    'schemaPath'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ThreadTargetInput
     */
    'ragFiles'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ThreadTargetInput
     */
    'dataSourceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ThreadTargetInput
     */
    'tableName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ThreadTargetInput
     */
    'askAIInputId'?: string;
}

export const ThreadTargetInputInputEntityTypeEnum = {
    Entity: 'ENTITY',
    Adhoc: 'ADHOC',
    ExternalDatasource: 'EXTERNAL_DATASOURCE',
    RagFiles: 'RAG_FILES',
    IntermediateEntity: 'INTERMEDIATE_ENTITY',
    PredictionService: 'PREDICTION_SERVICE'
} as const;

export type ThreadTargetInputInputEntityTypeEnum = typeof ThreadTargetInputInputEntityTypeEnum[keyof typeof ThreadTargetInputInputEntityTypeEnum];


