import React, { useMemo, useState } from "react";
import _, { groupBy, isEmpty, isEqual, size } from "lodash";
import { Grid, makeStyles, Typography } from "@material-ui/core";

import DataAppConfigDrawer from "../common/DataAppConfigDrawer";
import DataAppLogsDrawer from "../DataAppLogs/DataAppLogsDrawer";
import backgroundBottomImg from "src/assets/images/background-bottom.svg";
import backgroundTopImg from "src/assets/images/background-top.svg";
import { useProjectsStore } from "src/store/store";
import { DataAppsConfig } from "../utils/DataApps.constants";
import { DataAppsTable } from "./DataAppsTable/DataAppsTable";
import { DataAppsTiles } from "./DataAppsTiles/DataAppsTiles";
import { DataAppType } from "../DataApps.type";
import { EditDataAppDrawer } from "../EditDataAppDrawer/EditDataAppDrawer";
import { IProject } from "hooks/api";
import { defaultImages } from "../common/DataAppDefaultImage";
import { projectsGetter } from "src/store/store.selectors";
import { useGetRole } from "src/hooks/useGetRole";
import { ToggleView } from "src/components/custom";
import Search from "src/components/custom/Search/Search";
import { DataAppsFilter } from "./DataAppsFilter/DataAppsFilter";
import { listViewPages, PagesViewConfiguration } from "src/constants";
import MuiLink from "@material-ui/core/Link";
import EventBus from "src/utils/EventBus";
import { EVENTBUS_EVENTS } from "src/constants/eventbus.constants";
import NoDataFoundDefault from "src/pages/common/NoDataFoundDefault";

interface IProps {
  dataApps: DataAppType[];
  allDataApps: DataAppType[];
  onDelete: (dataApp: DataAppType) => void;
  isProjectDataApps: boolean;
  onSearch: (event: $TSFixMe) => void;
  setIsTilesView: (tilesView: boolean) => void;
  isTilesView: boolean;
  searchedDataApps: DataAppType[];
  selectedProjects: string[];
  searchValue?: string;
  setSelectedProjects: (selectedProjects: string[]) => void;
  onClearSearch?: () => void;
}

type StyleProps = {
  isProjectPath?: boolean;
  isProjectDataApps?: boolean;
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    // For Project Sub-Navbar
    marginTop: ({ isProjectDataApps }: StyleProps) => (!!isProjectDataApps ? 0 : 64),
    marginLeft: ({ isProjectDataApps }: StyleProps) => (!!isProjectDataApps ? 50 : 0),
    padding: ({ isProjectDataApps }: StyleProps) => (isProjectDataApps ? 16 : "0 10% 24px 10%"),
    width: "auto",
    // New UX change
    // The value 96px is the height of both the NavBars (TopNavBar 50px + SubTopNavBar 46px).
    height: ({ isProjectDataApps }: StyleProps) =>
      isProjectDataApps ? "calc(100vh - 96px)" : "calc(100vh - 228px)",
    overflowY: "auto",
    overflowX: "hidden"
  },
  containerBackground: {
    backgroundImage: `url(${backgroundTopImg}), url(${backgroundBottomImg})`,
    backgroundSize: "100% auto, 100% auto",
    backgroundRepeat: "no-repeat, no-repeat",
    backgroundPosition: "right top, right bottom"
  },
  headerContainer: {
    position: "sticky",
    top: 64,
    margin: "0 10%",
    zIndex: 1
  },
  title: {
    color: "#003656",
    fontSize: "32px",
    lineHeight: "37.5px",
    paddingBottom: "4px"
  },
  topAction: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
    marginLeft: "auto"
  },

  noDataAppImg: {
    height: "90px",
    width: "200px"
  }
});

export const DataAppsView: React.FC<IProps> = (props) => {
  const {
    dataApps,
    onDelete,
    isProjectDataApps = false,
    onSearch,
    isTilesView,
    setIsTilesView,
    selectedProjects,
    setSelectedProjects,
    searchedDataApps,
    allDataApps,
    searchValue,
    onClearSearch
  } = props;

  const projects = useProjectsStore(projectsGetter);
  const { isRoleYieldsDataAppView } = useGetRole();

  const isFiltered = useMemo(
    () => !isEqual(size(groupBy(allDataApps, "projectId")), size(selectedProjects)),
    [dataApps, selectedProjects]
  );

  const classes = useStyles({
    isProjectDataApps
  });

  const [configDataApp, setConfigDataApp] = useState<DataAppType | null>(null);
  const [logsDataApp, setLogsDataApp] = useState<DataAppType | null>(null);
  const [editingDataApp, setEditingDataApp] = useState<DataAppType | null>(null);

  const pagesViewConfiguration = JSON.parse(localStorage.getItem(PagesViewConfiguration) || "{}");

  const toggleView = (bool: boolean) => {
    setIsTilesView(bool);
    localStorage.setItem(
      PagesViewConfiguration,
      JSON.stringify({ ...pagesViewConfiguration, [listViewPages.DATAAPPS]: bool })
    );
  };

  const projectIdNameMap = useMemo(
    () =>
      projects?.reduce(
        (acc: any, project: IProject) => ({ ...acc, [project.id]: project.name }),
        {}
      ),
    [projects]
  );

  const openFilterDropdown = () => {
    EventBus.publish(EVENTBUS_EVENTS.OpenFilterDropdownDataApp);
  };

  const updatedDataApps = useMemo(() => {
    let imageIndex = 0;
    return dataApps.map((dataApp) => {
      const projectName = projectIdNameMap?.[dataApp.projectId];
      const updatedImage = !dataApp.iconUrl ? defaultImages[imageIndex] : null;
      const isLastImage = imageIndex === defaultImages.length - 1;
      imageIndex = dataApp.iconUrl ? imageIndex : isLastImage ? 0 : imageIndex + 1;
      const stoppedBy =
        dataApp.stoppedBy === "CleanupJob"
          ? "System (Due to Inactivity)"
          : dataApp.stoppedBy || " - ";
      return { ...dataApp, imgComponent: updatedImage, projectName, stoppedBy };
    });
  }, [dataApps, projectIdNameMap]);

  const handleClose = () => {
    setConfigDataApp(null);
  };

  const handleConfigOpen = (appInfo: DataAppType) => {
    setConfigDataApp(appInfo);
  };

  const handleDataAppEdit = (appInfo: DataAppType) => {
    setEditingDataApp(appInfo);
  };

  const handleLogsOpen = (appInfo: DataAppType) => {
    setLogsDataApp(appInfo);
  };

  const availableWindowSize = window.innerWidth * (1 - DataAppsConfig.ExemptingWidth);
  const numberOfCards = Math.floor((availableWindowSize + 24) / (DataAppsConfig.CardWidth + 24));

  const startLoc = isTilesView
    ? (availableWindowSize + 24 - numberOfCards * (DataAppsConfig.CardWidth + 24)) / 2
    : 0;

  return (
    <>
      {configDataApp && (
        <DataAppConfigDrawer
          open={!_.isEmpty(configDataApp)}
          dataApp={configDataApp}
          onClose={handleClose}
        />
      )}
      {editingDataApp && (
        <EditDataAppDrawer dataApp={editingDataApp} onClose={() => setEditingDataApp(null)} />
      )}
      {logsDataApp && (
        <DataAppLogsDrawer
          open={!_.isEmpty(logsDataApp)}
          dataApp={logsDataApp}
          onClose={() => setLogsDataApp(null)}
        />
      )}

      <Grid container {...(!isProjectDataApps ? { className: classes.containerBackground } : {})}>
        {!isProjectDataApps && (
          <Grid
            container
            className={classes.headerContainer}
            {...(isTilesView
              ? {
                  style: {
                    padding: `0 ${startLoc - 92}px 0 ${startLoc}px`,
                    width: availableWindowSize
                  }
                }
              : { style: { margin: "0 5% 0 10%" } })}>
            <>
              {dataApps?.length !== 0 && (
                <Grid item>
                  <Typography
                    variant="h5"
                    className={classes.title}
                    data-testid="dataAppsMainTitle">
                    DataApps
                  </Typography>

                  <Typography variant="subtitle1" data-testid="dataAppsCount">
                    Showing {updatedDataApps.length || 0} DataApps
                  </Typography>
                </Grid>
              )}

              {allDataApps?.length > 0 && (
                <Grid className={classes.topAction}>
                  <Search
                    value={searchValue}
                    onSearch={onSearch}
                    placeholder="Search DataApps"
                    data-testid="searchDataAppsField"
                  />
                  <DataAppsFilter
                    dataApps={allDataApps}
                    selectedProjects={selectedProjects}
                    filteredDataApps={searchedDataApps}
                    setSelectedProjects={setSelectedProjects}
                    projects={projects}
                  />
                  <ToggleView isPrimaryView={isTilesView} setIsPrimaryView={toggleView} />
                </Grid>
              )}
            </>
          </Grid>
        )}

        <Grid item container direction="column" wrap="nowrap" xs={12} className={classes.root}>
          <Grid
            item
            {...(isProjectDataApps
              ? {}
              : {
                  style: { padding: `16px ${startLoc}px`, width: availableWindowSize }
                })}>
            {isTilesView ? (
              <DataAppsTiles
                isProjectDataApps={isProjectDataApps}
                dataApps={updatedDataApps}
                isRoleYieldsDataAppView={isRoleYieldsDataAppView}
                onDelete={onDelete}
                onConfigOpen={handleConfigOpen}
                onEdit={handleDataAppEdit}
                onLogsOpen={handleLogsOpen}
              />
            ) : (
              !isEmpty(updatedDataApps) && (
                <DataAppsTable
                  isProjectDataApps={isProjectDataApps}
                  dataApps={updatedDataApps}
                  isRoleYieldsDataAppView={isRoleYieldsDataAppView}
                  onDelete={onDelete}
                  onEdit={handleDataAppEdit}
                  onConfigOpen={handleConfigOpen}
                  onLogsOpen={handleLogsOpen}
                />
              )
            )}
          </Grid>
          {!isProjectDataApps && (!dataApps || dataApps?.length === 0) && (
            <Grid container direction="column">
              <NoDataFoundDefault
                title={
                  isFiltered
                    ? "No dataApp found with applied filters"
                    : `No dataApp found with keyword "${searchValue}"`
                }
                subTitle={
                  isFiltered ? (
                    <Typography>
                      Please change{" "}
                      <MuiLink
                        style={{ textDecoration: "underline", cursor: "pointer" }}
                        onClick={openFilterDropdown}>
                        filter criteria
                      </MuiLink>{" "}
                      to view DataApps
                    </Typography>
                  ) : undefined
                }
                onClear={() => onClearSearch?.()}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};
