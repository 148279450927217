/* eslint-disable no-unused-vars */
import React from "react";
import {
  AutocompleteProps as MuiAutocompleteProps,
  Autocomplete as MuiAutocomplete,
  AutocompleteRenderInputParams
} from "@material-ui/lab";
import { TextField, ListItemText, Grid, makeStyles } from "@material-ui/core";
import { FixedSizeList } from "react-window";

interface Option {
  label: string;
  value: string;
}

//@ts-expect-error
interface AutocompleteProps extends MuiAutocompleteProps<Option, false, false, false> {
  options: Option[];
  value: string | null;
  label?: string;
  required?: boolean;
  disableClearable?: boolean;
  onChange: (event: any, selectedOption: string | null) => void;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
  virtualized?: boolean;
}

const useStyles = makeStyles({
  selectOption: {
    whiteSpace: "normal"
  }
});

const MAX_VISIBLE_ITEMS = 5;
const ITEM_HEIGHT = 48;

export const Autocomplete: React.FC<AutocompleteProps> = ({
  options,
  label,
  value,
  onChange,
  required,
  virtualized,
  ...restProps
}) => {
  const classes = useStyles();

  const renderVirtualizedList = (listboxProps: any) => {
    const itemCount = options.length;

    const Row = ({ index, style }: { index: number; style: React.CSSProperties }) => {
      const option = options[index];
      const selected = value === option.value;

      const onRowClick = (event: any) => {
        onChange(event, selected ? null : option.value);
      };

      return (
        <Grid
          container
          direction="row"
          wrap="nowrap"
          alignItems="center"
          style={style}
          key={option.value}
          onClick={onRowClick}
          {...listboxProps}>
          <ListItemText
            primary={option.label}
            style={{
              padding: "8px",
              backgroundColor: selected ? "rgba(0, 0, 0, 0.08)" : "transparent"
            }}
          />
        </Grid>
      );
    };

    return (
      <FixedSizeList
        height={Math.min(itemCount, MAX_VISIBLE_ITEMS) * ITEM_HEIGHT}
        width="100%"
        itemSize={ITEM_HEIGHT}
        itemCount={itemCount}>
        {Row}
      </FixedSizeList>
    );
  };

  return (
    <MuiAutocomplete
      classes={{
        option: classes.selectOption
      }}
      options={options}
      value={options.find((option) => option.value === value) || null}
      getOptionLabel={(option: Option) => option.label}
      getOptionSelected={(option: Option, value: Option) => option.value === value.value}
      onChange={(event, newValue) => {
        onChange(event, newValue ? newValue.value : null);
      }}
      ListboxComponent={virtualized ? renderVirtualizedList : undefined}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          variant="outlined"
          label={label}
          placeholder=""
        />
      )}
      {...restProps}
    />
  );
};
