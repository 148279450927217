/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { Descriptor } from './descriptor';
// May contain unused imports in some cases
// @ts-ignore
import { EnumDescriptor } from './enum-descriptor';
// May contain unused imports in some cases
// @ts-ignore
import { FieldDescriptorProto } from './field-descriptor-proto';
// May contain unused imports in some cases
// @ts-ignore
import { FieldOptions } from './field-options';
// May contain unused imports in some cases
// @ts-ignore
import { FileDescriptor } from './file-descriptor';
// May contain unused imports in some cases
// @ts-ignore
import { OneofDescriptor } from './oneof-descriptor';

/**
 * 
 * @export
 * @interface FieldDescriptor
 */
export interface FieldDescriptor {
    /**
     * 
     * @type {number}
     * @memberof FieldDescriptor
     */
    'index'?: number;
    /**
     * 
     * @type {FieldDescriptorProto}
     * @memberof FieldDescriptor
     */
    'proto'?: FieldDescriptorProto;
    /**
     * 
     * @type {string}
     * @memberof FieldDescriptor
     */
    'fullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FieldDescriptor
     */
    'jsonName'?: string;
    /**
     * 
     * @type {FileDescriptor}
     * @memberof FieldDescriptor
     */
    'file'?: FileDescriptor;
    /**
     * 
     * @type {Descriptor}
     * @memberof FieldDescriptor
     */
    'extensionScope'?: Descriptor;
    /**
     * 
     * @type {string}
     * @memberof FieldDescriptor
     */
    'type'?: FieldDescriptorTypeEnum;
    /**
     * 
     * @type {Descriptor}
     * @memberof FieldDescriptor
     */
    'containingType'?: Descriptor;
    /**
     * 
     * @type {Descriptor}
     * @memberof FieldDescriptor
     */
    'messageType'?: Descriptor;
    /**
     * 
     * @type {OneofDescriptor}
     * @memberof FieldDescriptor
     */
    'containingOneof'?: OneofDescriptor;
    /**
     * 
     * @type {EnumDescriptor}
     * @memberof FieldDescriptor
     */
    'enumType'?: EnumDescriptor;
    /**
     * 
     * @type {object}
     * @memberof FieldDescriptor
     */
    'defaultValue'?: object;
    /**
     * 
     * @type {string}
     * @memberof FieldDescriptor
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof FieldDescriptor
     */
    'number'?: number;
    /**
     * 
     * @type {string}
     * @memberof FieldDescriptor
     */
    'javaType'?: FieldDescriptorJavaTypeEnum;
    /**
     * 
     * @type {FieldOptions}
     * @memberof FieldDescriptor
     */
    'options'?: FieldOptions;
    /**
     * 
     * @type {boolean}
     * @memberof FieldDescriptor
     */
    'optional'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldDescriptor
     */
    'required'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldDescriptor
     */
    'extension'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldDescriptor
     */
    'repeated'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldDescriptor
     */
    'mapField'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FieldDescriptor
     */
    'liteType'?: FieldDescriptorLiteTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FieldDescriptor
     */
    'liteJavaType'?: FieldDescriptorLiteJavaTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof FieldDescriptor
     */
    'packed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldDescriptor
     */
    'packable'?: boolean;
    /**
     * 
     * @type {OneofDescriptor}
     * @memberof FieldDescriptor
     */
    'realContainingOneof'?: OneofDescriptor;
}

export const FieldDescriptorTypeEnum = {
    Double: 'DOUBLE',
    Float: 'FLOAT',
    Int64: 'INT64',
    Uint64: 'UINT64',
    Int32: 'INT32',
    Fixed64: 'FIXED64',
    Fixed32: 'FIXED32',
    Bool: 'BOOL',
    String: 'STRING',
    Group: 'GROUP',
    Message: 'MESSAGE',
    Bytes: 'BYTES',
    Uint32: 'UINT32',
    Enum: 'ENUM',
    Sfixed32: 'SFIXED32',
    Sfixed64: 'SFIXED64',
    Sint32: 'SINT32',
    Sint64: 'SINT64'
} as const;

export type FieldDescriptorTypeEnum = typeof FieldDescriptorTypeEnum[keyof typeof FieldDescriptorTypeEnum];
export const FieldDescriptorJavaTypeEnum = {
    Int: 'INT',
    Long: 'LONG',
    Float: 'FLOAT',
    Double: 'DOUBLE',
    Boolean: 'BOOLEAN',
    String: 'STRING',
    ByteString: 'BYTE_STRING',
    Enum: 'ENUM',
    Message: 'MESSAGE'
} as const;

export type FieldDescriptorJavaTypeEnum = typeof FieldDescriptorJavaTypeEnum[keyof typeof FieldDescriptorJavaTypeEnum];
export const FieldDescriptorLiteTypeEnum = {
    Double: 'DOUBLE',
    Float: 'FLOAT',
    Int64: 'INT64',
    Uint64: 'UINT64',
    Int32: 'INT32',
    Fixed64: 'FIXED64',
    Fixed32: 'FIXED32',
    Bool: 'BOOL',
    String: 'STRING',
    Group: 'GROUP',
    Message: 'MESSAGE',
    Bytes: 'BYTES',
    Uint32: 'UINT32',
    Enum: 'ENUM',
    Sfixed32: 'SFIXED32',
    Sfixed64: 'SFIXED64',
    Sint32: 'SINT32',
    Sint64: 'SINT64'
} as const;

export type FieldDescriptorLiteTypeEnum = typeof FieldDescriptorLiteTypeEnum[keyof typeof FieldDescriptorLiteTypeEnum];
export const FieldDescriptorLiteJavaTypeEnum = {
    Int: 'INT',
    Long: 'LONG',
    Float: 'FLOAT',
    Double: 'DOUBLE',
    Boolean: 'BOOLEAN',
    String: 'STRING',
    ByteString: 'BYTE_STRING',
    Enum: 'ENUM',
    Message: 'MESSAGE'
} as const;

export type FieldDescriptorLiteJavaTypeEnum = typeof FieldDescriptorLiteJavaTypeEnum[keyof typeof FieldDescriptorLiteJavaTypeEnum];


