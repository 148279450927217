import React, { useMemo, useState } from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { find, get, isArray, isEmpty } from "lodash";
import { Spinner, SplashSection } from "../../components";
import backgroundTopImg from "src/assets/images/background-top.svg";
import backgroundBottomImg from "src/assets/images/background-bottom.svg";

// Icons
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { SplashIcon } from "src/icons/NewUX";

import CreateProject from "./CreateProject";
import { deleteProjectWithRethrow, getDashboardProjects } from "../../api";
import { useDrawerStore, useProjectsStore } from "../../store/store";
import {
  projectsGetter,
  projectsSetter,
  shouldRefreshProjectsToggler,
  sideComponentSetter
} from "../../store/store.selectors";
import { ProjectsList } from "./ProjectsList";
import { ProjectsCards } from "./ProjectsCards";
import { toastWrapper } from "src/utils/toastWrapper";
import styles from "./Projects.module.scss";
import ProjectSettings from "./ProjectSettings/ProjectSettings";
import { ProjectSettingsDrawerTitle } from "./common/ProjectSettingsDrawerTitle";
import { IProject } from "src/hooks/api";
import { usePrivateRouteContext } from "src/routing/PrivateRoute/context/usePrivateRouteContext";

// Helpers
import { deleteProjectMessageLines } from "src/pages/private/ProjectsModule/utils/Projects.helpers";

// Components
import CopyProjectModal from "./CopyProject/components/CopyProjectModal";
import CreateProjectButton from "./CreateProjectButton";
import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import Search from "src/components/custom/Search/Search";
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import { ProjectImageModal } from "./ProjectImage.tsx/ProjectImageModal";
import { ToggleView } from "src/components/custom";
import { WebPaths } from "src/routing/routes";
import { listViewPages, PagesViewConfiguration } from "src/constants";
import NoDataFoundDefault from "../common/NoDataFoundDefault";

const useStyles = makeStyles((theme) => ({
  containerBackground: {
    height: "100%",
    backgroundImage: `url(${backgroundTopImg}), url(${backgroundBottomImg})`,
    backgroundSize: "100% auto, 100% auto",
    backgroundRepeat: "no-repeat, no-repeat",
    backgroundPosition: "right top, right bottom"
  },
  headerContainer: {
    position: "sticky",
    top: 64,
    margin: "0 10%",
    zIndex: 1
  },
  gridContainer: {
    height: "calc(100vh - 64px - 254px)",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.grey[700]
  },
  gridIcon: {
    height: 215,
    width: 215,
    marginRight: 140
  },
  projectLabel: {
    color: "#003656",
    fontSize: "32px",
    lineHeight: "37.5px",
    paddingBottom: "4px"
  },
  topActions: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
    marginLeft: "auto"
  }
}));

const Projects = () => {
  const {
    containerBackground,
    headerContainer,
    gridContainer,
    gridIcon,
    projectLabel,
    topActions
  } = useStyles();
  const [projectIdState, setProjectIdState] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const pagesViewConfiguration = JSON.parse(localStorage.getItem(PagesViewConfiguration) || "{}");
  const isTileView = get(pagesViewConfiguration, listViewPages.PROJECTS, true);

  const [tilesView, setTilesView] = useState(isTileView);
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectProjectId, setSelectedProjectId] = useState<string | null>(null);
  const [copyProjectId, setCopyProjectId] = useState<string | null>(null);

  const { isFetchingProjects, isFetchingJobs } = usePrivateRouteContext();
  const isLoading = isFetchingProjects || isFetchingJobs;

  const handleChangeView = (bool: boolean) => {
    setTilesView(bool);
    localStorage.setItem(
      PagesViewConfiguration,
      JSON.stringify({ ...pagesViewConfiguration, [listViewPages.PROJECTS]: bool })
    );
  };

  const projectList = useProjectsStore(projectsGetter);
  const setProjects = useProjectsStore(projectsSetter);
  const toggleShouldProjectsRefresh = useProjectsStore(shouldRefreshProjectsToggler);
  const setSideComponent = useDrawerStore(sideComponentSetter);

  const handleDeleteV2 = async () => {
    try {
      setIsDeleting(true);
      await deleteProjectWithRethrow(projectIdState, true);
      const projects = await getDashboardProjects();
      isArray(projects) && setProjects(projects);
      toastWrapper({
        type: "success",
        content: "Project deleted successfully!"
      });
      setProjectIdState("");
    } catch (error) {
      console.error({ error });
    } finally {
      setSideComponent({
        sideComponent: null,
        sideComponentProps: null
      });
      setIsDeleting(false);
    }
  };

  const handleEdit = (projectId: string) => {
    setSideComponent({
      sideComponent: ProjectSettings,
      sideComponentProps: {
        title: (
          <ProjectSettingsDrawerTitle id={projectId} onDelete={() => handleDelete(projectId)} />
        ),
        data: { projectId, projects: projectList }
      }
    });
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseModal = async () => {
    await handleDeleteV2();
  };

  const handleCancelClose = () => {
    setProjectIdState("");
  };

  const handleDelete = (id: string) => {
    setProjectIdState(id);
  };

  const handleSearch = ({ target: { value } }: $TSFixMe) => {
    setSearchValue(value);
  };

  const sortedList = (list: $TSFixMe) =>
    // @ts-expect-error TS(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
    list.sort((a: $TSFixMe, b: $TSFixMe) => new Date(b.lastUpdated) - new Date(a.lastUpdated));

  const parsedList = useMemo(() => {
    if (searchValue) {
      return projectList?.filter((project: IProject) =>
        project?.name?.toLowerCase().includes(searchValue?.toLowerCase())
      );
    }
    return sortedList(projectList);
  }, [searchValue, projectList]);

  const copyProject = useMemo(
    () => find(parsedList, (proj) => proj.id === copyProjectId),
    [parsedList, copyProjectId]
  );
  const selectedProject = useMemo(
    () => find(parsedList, (proj) => proj.id === selectProjectId),
    [parsedList, selectProjectId]
  );

  const availableWindowSize = window.innerWidth * 0.8;
  const numberOfCards = Math.floor((availableWindowSize + 24) / 380);

  const startLoc = tilesView ? (availableWindowSize + 24 - numberOfCards * 380) / 2 : 0;

  const project = useMemo(
    () => projectList?.find((eachProject: $TSFixMe) => eachProject?.id === projectIdState),
    [projectList, projectIdState]
  );

  return (
    <>
      {!!projectIdState && (
        <NewThemeWrapper>
          <Modal
            open={true}
            variant={ModalVariants.Delete}
            title="Delete Project"
            content={deleteProjectMessageLines(project?.name)}
            isSubmitting={isDeleting}
            onClose={handleCancelClose}
            onSubmit={handleCloseModal}
          />
        </NewThemeWrapper>
      )}
      {selectProjectId && (
        <ProjectImageModal
          project={selectedProject}
          handleClose={() => setSelectedProjectId(null)}
          onSuccess={() => toggleShouldProjectsRefresh()}
        />
      )}
      {copyProjectId && copyProject?.name && (
        <CopyProjectModal
          id={copyProjectId}
          name={copyProject.name}
          envId={copyProject.envId}
          open={!!copyProjectId}
          onClose={() => setCopyProjectId(null)}
        />
      )}
      {open && <CreateProject open={true} onClose={handleClose} />}

      <SubTopNavBarWrapper
        subTopNavBarLeftSection={{
          hideBackNav: true,
          component: (
            <Link to={WebPaths.Dashboard}>
              <span>
                <Typography gutterBottom variant="body2" color="textPrimary">
                  <ArrowBackIosIcon fontSize="inherit" />
                </Typography>
              </span>
            </Link>
          )
        }}
        subTopNavBarRightSection={{
          component: (
            <>
              <CreateProjectButton onNewProjectClick={handleClickOpen} />
            </>
          )
        }}
      />

      <Grid container className={containerBackground}>
        <NewThemeWrapper>
          <Grid
            item
            container
            className={headerContainer}
            style={{ padding: `0 ${startLoc}px` }}
            alignItems="center">
            {!isLoading && parsedList.length !== 0 && (
              <Grid item>
                <Typography data-testid="projects" variant="h5" className={projectLabel}>
                  Projects
                </Typography>
                <Typography data-testid="projectsCountText" variant="subtitle1">
                  Showing {parsedList.length || 0} Projects
                </Typography>
              </Grid>
            )}
            {!isLoading && projectList?.length > 0 && (
              <Grid className={topActions}>
                <Search value={searchValue} onSearch={handleSearch} placeholder="Search projects" />
                <ToggleView isPrimaryView={tilesView} setIsPrimaryView={handleChangeView} />
              </Grid>
            )}
          </Grid>
          <Grid
            item
            container
            direction="column"
            wrap="nowrap"
            xs={12}
            className={styles.displayProjects}>
            <Grid
              item
              container
              style={{
                padding: `16px ${startLoc}px`,
                width: availableWindowSize
              }}>
              {isLoading ? (
                <Spinner />
              ) : isEmpty(projectList) ? (
                <SplashSection
                  gridContainerStyle={gridContainer}
                  gridIconStyle={gridIcon}
                  button={{
                    color: "primary",
                    onClick: handleClickOpen,
                    label: "+ NEW PROJECT",
                    dataTestId: "addProject"
                  }}
                  titleSection={{
                    title: "Get Started with a New Project",
                    subtitleLines: [
                      "Welcome to the projects page.",
                      "Start a new one by clicking on the button below."
                    ]
                  }}
                  icon={<SplashIcon />}
                />
              ) : isEmpty(parsedList) && !!searchValue ? (
                <NoDataFoundDefault
                  title={`No project found with keyword "${searchValue}"`}
                  onClear={() => setSearchValue("")}
                />
              ) : tilesView ? (
                <ProjectsCards
                  list={parsedList}
                  isLoading={isLoading}
                  onEdit={handleEdit}
                  onDelete={handleDelete}
                  onCopyOpen={setCopyProjectId}
                  onImageOpen={setSelectedProjectId}
                />
              ) : (
                <ProjectsList
                  list={parsedList}
                  isLoading={isLoading}
                  onEdit={handleEdit}
                  onDelete={handleDelete}
                  onCopyOpen={setCopyProjectId}
                  onImageOpen={setSelectedProjectId}
                />
              )}
            </Grid>
          </Grid>
        </NewThemeWrapper>
      </Grid>
    </>
  );
};

export default Projects;
