import React, { useMemo } from "react";
import _ from "lodash";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from "@material-ui/core";

import CreateDataAppInputs from "./CreateDataAppInputs";
import DataAppModelControls from "./DataAppModelControls";
import EnableResponseCache from "./EnableResponseCache";
import SelectAskAIInputType from "./SelectAskAIInputType";
import SelectAskAILLMModel from "./SelectAskAILLMModel";
import SelectDataAppSecurity from "./SelectDataAppSecurity";
import { AppTemplate } from "src/pages/DataApps/DataApps.type";
import {
  DataappAskAIConfigInputTypeEnum,
  DataappAskAIConfigLlmTypeEnum,
  CreateDataAppRequestDtoDataAppTypeEnum,
  DataappAskAIConfigDataSharingStrategyEnum,
  DataappAskAIConfig
} from "openapi/Models";
import { IRecipes } from "./CreateDataApp";
import { handleResponse } from "src/utils/apiService";
import { useCreateDataAppMutation } from "src/hooks/api/dataapps/useCreateDataAppMutation";
import { useForm } from "src/utils/useForm";
import { useParams } from "react-router-dom";
import { validateNameField } from "src/utils/formFieldUtils";

interface IProps {
  type: CreateDataAppRequestDtoDataAppTypeEnum;
  recipes: IRecipes[];
  appTemplates?: AppTemplate[];
  askAIDataAppTemplateId?: string;
  onBack: () => void;
  onSuccess: () => void;
}

export const dataAppConfigFields = {
  llmModel: "llmModel",
  inputType: "inputType",
  dataAppName: "dataAppName",
  description: "description",
  imageBase64: "imageBase64",
  recipeId: "recipeId",
  systemMessage: "systemMessage",
  security: "security",
  enableCache: "enableCache",
  customEnvId: "customEnvId"
};

const initialValues = {
  [dataAppConfigFields.dataAppName]: "",
  [dataAppConfigFields.description]: ""
};

const CreateDataAppForm: React.FC<IProps> = (props) => {
  const { recipes, type, appTemplates, askAIDataAppTemplateId, onBack, onSuccess } = props;

  const { projectId } = useParams();
  const createDataAppMutation = useCreateDataAppMutation();
  const allowedRecipes = useMemo(() => recipes?.filter((recipe) => recipe.allowed), [recipes]);

  const isAskAI = type === CreateDataAppRequestDtoDataAppTypeEnum.Askai;
  const { values, handleInputChange, setValues } = useForm({
    ...initialValues,
    [dataAppConfigFields.recipeId]:
      type === CreateDataAppRequestDtoDataAppTypeEnum.RapidModel && allowedRecipes?.length === 1
        ? allowedRecipes?.[0]?.id
        : "",
    [dataAppConfigFields.llmModel]: isAskAI
      ? DataappAskAIConfigLlmTypeEnum.OpenaiGpt4Turbo
      : undefined,
    [dataAppConfigFields.inputType]: isAskAI
      ? DataappAskAIConfigInputTypeEnum.ProjectCanvas
      : undefined,
    [dataAppConfigFields.security]: isAskAI
      ? DataappAskAIConfigDataSharingStrategyEnum.SampleData
      : undefined,
    [dataAppConfigFields.enableCache]: isAskAI ? false : undefined
  });

  const errorMsgs = useMemo(() => {
    const name = _.trim(_.get(values, dataAppConfigFields.dataAppName));

    const { error } = validateNameField({
      fieldName: name,
      fieldNameLabel: "dataApp name"
    });

    return {
      [dataAppConfigFields.dataAppName]: name ? error : ""
    };
  }, [values]);

  const disabledTooltipMsg = useMemo(() => {
    const name = _.trim(_.get(values, dataAppConfigFields.dataAppName));
    if (type === CreateDataAppRequestDtoDataAppTypeEnum.Askai) {
      if (!name) {
        return "Please input DataApp Name to enable";
      }

      if (!!_.get(errorMsgs, dataAppConfigFields.dataAppName)) {
        return "Please use valid DataApp Name to enable";
      }

      if (!_.get(values, dataAppConfigFields.inputType)) {
        return "Please select an input to enable";
      }

      if (!_.get(values, dataAppConfigFields.customEnvId)) {
        return "Please wait until the environment is loaded for this DataApp's AskAI";
      }

      return "";
    } else {
      const recipeId = _.get(values, dataAppConfigFields.recipeId);
      if (!name || !recipeId) {
        return "Please input DataApp Name and select Recipe to enable";
      }

      if (!!_.get(errorMsgs, dataAppConfigFields.dataAppName)) {
        return "Please use valid DataApp Name to enable";
      }

      if (!_.get(values, dataAppConfigFields.customEnvId)) {
        return "Please wait until the environment is loaded for this DataApp's AskAI";
      }

      return "";
    }
  }, [values, type, errorMsgs]);

  const handleSubmit = () => {
    if (projectId) {
      if (type === CreateDataAppRequestDtoDataAppTypeEnum.Askai) {
        let askAIConfig: DataappAskAIConfig;
        if (askAIDataAppTemplateId) {
          if (
            _.get(values, dataAppConfigFields.inputType) ===
            DataappAskAIConfigInputTypeEnum.PredictionService
          ) {
            askAIConfig = {
              inputType: _.get(values, dataAppConfigFields.inputType),
              customEnvId: _.get(values, dataAppConfigFields.customEnvId)
            };
          } else {
            askAIConfig = {
              llmType: _.get(values, dataAppConfigFields.llmModel),
              inputType: _.get(values, dataAppConfigFields.inputType),
              systemMessage: _.get(values, dataAppConfigFields.systemMessage),
              enableCache: _.get(values, dataAppConfigFields.enableCache),
              dataSharingStrategy:
                _.get(values, dataAppConfigFields.inputType) !==
                DataappAskAIConfigInputTypeEnum.RagFiles
                  ? _.get(values, dataAppConfigFields.security)
                  : undefined,
              customEnvId: _.get(values, dataAppConfigFields.customEnvId)
            };
          }

          createDataAppMutation.mutate({
            dataAppName: _.get(values, dataAppConfigFields.dataAppName),
            description: _.get(values, dataAppConfigFields.description),
            appTemplateId: askAIDataAppTemplateId,
            projectId,
            iconUrl: _.get(values, dataAppConfigFields.imageBase64),
            dataAppType: type,
            askAIConfig,
            onSuccess
          });
        } else {
          handleResponse({
            errorMessage: "Please make sure to publish ask AI template before creating the app"
          });
        }
        return;
      }

      if (type === CreateDataAppRequestDtoDataAppTypeEnum.RapidModel) {
        const currRecipe = recipes.find(
          (recipe) => recipe.id === _.get(values, dataAppConfigFields.recipeId)
        );
        const currentAppTemplate = appTemplates?.find(
          (appTemplate: any) =>
            appTemplate.autoMlProblemType?.toLowerCase() === currRecipe?.metadata?.problemType &&
            appTemplate.buildStatus
        );
        if (!currentAppTemplate) {
          handleResponse({ errorMessage: `App Template not found for the specified recipe;` });
        }

        projectId &&
          currentAppTemplate &&
          currRecipe &&
          createDataAppMutation.mutate({
            dataAppName: _.get(values, dataAppConfigFields.dataAppName),
            description: _.get(values, dataAppConfigFields.description),
            projectId,
            iconUrl: _.get(values, dataAppConfigFields.imageBase64),
            appTemplateId: currentAppTemplate.id,
            recipeId: currRecipe.id,
            metadata: currRecipe?.runConfigs?.[0]?.variables,
            dataAppType: type,
            askAIConfig: {
              systemMessage: _.get(values, dataAppConfigFields.systemMessage),
              customEnvId: _.get(values, dataAppConfigFields.customEnvId)
            },
            onSuccess
          });
        return;
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [e.target.name]: e.target.checked
    });
  };

  const handleInputValChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      [dataAppConfigFields.llmModel]:
        DataappAskAIConfigInputTypeEnum.RagFiles === e.target.value &&
        _.includes(
          [
            DataappAskAIConfigLlmTypeEnum.AzureOpenaiGpt4O,
            DataappAskAIConfigLlmTypeEnum.AnthropicClaude35Sonnet
          ],
          _.get(values, dataAppConfigFields.llmModel)
        )
          ? DataappAskAIConfigLlmTypeEnum.OpenaiGpt4Turbo
          : _.get(values, dataAppConfigFields.llmModel)
    });
  };

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{
          display: "flex",
          marginBottom: "10px"
        }}>
        <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <IconButton style={{ padding: 0 }} onClick={onBack} data-testid="dataAppBackBtn">
            <ArrowBackIcon />
          </IconButton>
          <Typography component="h3" style={{ color: "#515151" }} data-testid="dataAppConfigType">
            {type === CreateDataAppRequestDtoDataAppTypeEnum.Askai ? "Custom" : "Model"}{" "}
            Configuration
          </Typography>
        </div>
        <Tooltip title={disabledTooltipMsg}>
          <span>
            <Button
              size="small"
              disabled={!!disabledTooltipMsg || createDataAppMutation.isLoading}
              variant="contained"
              startIcon={
                createDataAppMutation.isLoading ? <CircularProgress size={16} /> : undefined
              }
              data-testid="createDatAppBtn"
              color="primary"
              onClick={handleSubmit}>
              Create
            </Button>
          </span>
        </Tooltip>
      </Grid>
      <Grid container direction="column" spacing={2}>
        <Grid item xs={12}>
          <CreateDataAppInputs
            type={type}
            isAskAI={type === CreateDataAppRequestDtoDataAppTypeEnum.Askai}
            values={values}
            errorMsgs={errorMsgs}
            recipes={recipes}
            onChange={handleInputChange}
          />
        </Grid>
        {type === CreateDataAppRequestDtoDataAppTypeEnum.RapidModel && (
          <Grid item xs={12}>
            <Card style={{ background: "#fff", padding: "16px" }}>
              <DataAppModelControls values={values} onChange={handleInputChange} />
            </Card>
          </Grid>
        )}
        {type === CreateDataAppRequestDtoDataAppTypeEnum.Askai && (
          <Grid item container direction="row" xs={12} spacing={2} style={{ margin: 0 }}>
            <Grid item xs={"auto"} style={{ minHeight: "100%", width: "315px", paddingLeft: 0 }}>
              <Card style={{ background: "#fff", padding: "16px", height: "100%" }}>
                <SelectAskAIInputType
                  value={_.get(values, dataAppConfigFields.inputType)}
                  onChange={handleInputValChange}
                />
              </Card>
            </Grid>
            {_.get(values, dataAppConfigFields.inputType) !==
              DataappAskAIConfigInputTypeEnum.PredictionService && (
              <Grid item xs style={{ padding: "8px 0px 8px 8px" }}>
                <Card style={{ background: "#fff", padding: "16px", height: "100%" }}>
                  <Grid container direction="row">
                    <Grid
                      item
                      xs={7}
                      style={{
                        minHeight: "100%",
                        padding: "0px 16px 0px 0px",
                        display: "flex",
                        flex: "column",
                        gap: "24px",
                        flexDirection: "column"
                      }}>
                      <SelectAskAILLMModel
                        inputType={_.get(values, dataAppConfigFields.inputType)}
                        value={_.get(values, dataAppConfigFields.llmModel)}
                        onChange={handleInputChange}
                      />
                      <DataAppModelControls values={values} onChange={handleInputChange} />
                    </Grid>

                    <Grid
                      item
                      xs={5}
                      style={{
                        minHeight: "100%",
                        padding: "4px 16px",
                        borderLeft: "1px solid #e5e5e5",
                        display: "flex",
                        flexDirection: "column",
                        gap: "24px"
                      }}>
                      <EnableResponseCache
                        value={_.get(values, dataAppConfigFields.enableCache)}
                        onChange={handleChange}
                      />
                      {_.get(values, dataAppConfigFields.inputType) !==
                        DataappAskAIConfigInputTypeEnum.RagFiles && (
                        <SelectDataAppSecurity
                          value={_.get(values, dataAppConfigFields.security)}
                          onChange={handleInputChange}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default CreateDataAppForm;
