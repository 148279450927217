import React from "react";
import _ from "lodash";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Tooltip,
  makeStyles
} from "@material-ui/core";

import { DataappAskAIConfigInputTypeEnum, DataappAskAIConfigLlmTypeEnum } from "openapi/Models";
import { dataAppConfigFields } from "./CreateDataAppForm";

const useStyles = makeStyles({
  link: {
    color: ({ disabled }: { disabled?: boolean }) => (disabled ? "#00000061" : "#003656"),

    "&:hover": {
      textDecoration: "none"
    }
  }
});

interface IProps {
  readOnly?: boolean;
  value: DataappAskAIConfigLlmTypeEnum;
  inputType?: DataappAskAIConfigInputTypeEnum;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SelectAskAILLMModel: React.FC<IProps> = (props) => {
  const { link } = useStyles({ disabled: props.readOnly });

  return (
    <FormControl component="fieldset" disabled={props.readOnly}>
      <FormLabel component="legend">Models</FormLabel>
      <RadioGroup
        row={props.readOnly}
        aria-label={dataAppConfigFields.llmModel}
        name={dataAppConfigFields.llmModel}
        value={props.value}
        data-testid="dataAppConfigLlmType"
        onChange={props.onChange}>
        <FormControlLabel
          value={DataappAskAIConfigLlmTypeEnum.OpenaiGpt4Turbo}
          control={<Radio />}
          label={
            <>
              <a
                className={link}
                href="https://platform.openai.com/docs/models/gpt-4-turbo-and-gpt-4"
                target="_blank"
                rel="noreferrer">
                OpenAI GPT-4 Turbo
              </a>{" "}
              <Tooltip title="This is recommended for better accuracy of responses">
                <InfoOutlinedIcon
                  style={{ fontSize: "1rem", marginBottom: "2px", color: "#8a8989" }}
                />
              </Tooltip>
            </>
          }
        />
        <FormControlLabel
          value={DataappAskAIConfigLlmTypeEnum.OpenaiGpt4O}
          control={<Radio />}
          label={
            <a
              className={link}
              href="https://platform.openai.com/docs/models/gpt-4o"
              rel="noreferrer"
              target="_blank">
              OpenAI GPT-4o
            </a>
          }
        />
        {props.inputType !== DataappAskAIConfigInputTypeEnum.RagFiles && (
          <FormControlLabel
            value={DataappAskAIConfigLlmTypeEnum.AzureOpenaiGpt4O}
            control={<Radio />}
            label={
              <a
                className={link}
                href="https://learn.microsoft.com/en-us/azure/ai-services/openai/concepts/models#gpt-4o-and-gpt-4-turbo"
                rel="noreferrer"
                target="_blank">
                Azure OpenAI GPT-4o
              </a>
            }
          />
        )}
        {props.inputType !== DataappAskAIConfigInputTypeEnum.RagFiles && (
          <FormControlLabel
            value={DataappAskAIConfigLlmTypeEnum.AnthropicClaude35Sonnet}
            control={<Radio />}
            label={
              <>
                <a
                  className={link}
                  href="https://docs.anthropic.com/en/docs/intro-to-claude"
                  rel="noreferrer"
                  target="_blank">
                  Anthropic Claude 3.5 Sonnet
                </a>{" "}
                <i>(beta)</i>{" "}
                <Tooltip title="Integration with Claude is currently in beta stage. Responses might be slower and incorrect">
                  <InfoOutlinedIcon
                    style={{ fontSize: "1rem", marginBottom: "2px", color: "#8a8989" }}
                  />
                </Tooltip>
              </>
            }
          />
        )}
      </RadioGroup>
    </FormControl>
  );
};

export default SelectAskAILLMModel;
