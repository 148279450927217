import React, { useMemo, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { filter, includes, toLower } from "lodash";
import { FixedSizeList } from "react-window";

import {
  InputLabel,
  MenuItem,
  FormControl,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  FormHelperText,
  Tooltip,
  MenuItemProps,
  ListSubheader
} from "@material-ui/core";
import SelectSearch from "src/components/Inputs/SelectSearch";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    margin: 0,
    background: "#fff"
  },
  formControlSmall: {
    "& .MuiInputBase-root": {
      padding: 0,
      paddingBottom: 7,
      fontSize: 14
    }
  },
  select: {
    "& .MuiSelect-root": {
      display: "flex",
      alignItems: "center"
    },
    "& .MuiSelect-outlined": {
      backgroundColor: "#ffffff"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#D8D8D8",
      borderWidth: "1px"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#D8D8D8"
    }
  },
  selectMenu: {
    maxWidth: "400px",
    overflow: "auto",
    "& .Mui-selected": {
      color: theme.palette.primary.main,
      backgroundColor: "#F2F9FF"
    }
  },
  fixedMenuHeight: {
    maxHeight: 250
  },
  icon: {
    fill: theme.palette.primary.main
  },
  menuItem: {
    "&.Mui-disabled": {
      pointerEvents: "auto",
      backgroundColor: "none"
    }
  },
  subHeader: {
    borderTop: "1px solid #0003"
  }
}));

interface SelectValue {
  label: React.ReactNode;
  value: string;
  disabled?: boolean;
  helpText?: string;
  rightComponent?: React.ReactNode;
  category?: string;
}

interface SelectProps extends MuiSelectProps {
  values: SelectValue[];
  hideInputLabel?: boolean;
  size?: string;
  fixedMenuHeight?: boolean;
  helperText?: string;
  menuProps?: any;
  onChange?: (event: any) => void;
}

export const NO_TARGET_COL_TEXT = "I don’t have a Target Column";

const TargetColumnSelect = ({
  label,
  value,
  variant = "filled",
  size = "medium",
  disabled,
  values,
  onChange,
  fullWidth,
  margin = "dense",
  inputProps,
  placeholder,
  hideInputLabel = false,
  fixedMenuHeight = false,
  required,
  helperText,
  menuProps = {},
  ...restProps
}: SelectProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const filtered = useMemo(() => {
    return filter(values, ({ value }) => includes(toLower(value.toString()), toLower(search)));
  }, [values, search]);

  const renderRow = ({ index, style }: { index: number; style: React.CSSProperties }) => {
    const item = filtered[index];
    return (
      <div style={style} key={item.value}>
        <MenuItemWithTooltip
          id={item.value}
          helpText={item.helpText || ""}
          value={item.value}
          disabled={item.disabled}
          onClick={() => {
            if (item.disabled) {
              return;
            }
            onChange?.(item.value);
            handleClose();
          }}
          className={classes.menuItem}>
          {item.label} {item.rightComponent}
        </MenuItemWithTooltip>
      </div>
    );
  };

  return (
    <FormControl
      fullWidth={fullWidth}
      variant={variant}
      className={clsx(classes.formControl, {
        [classes.formControlSmall]: size === "small"
      })}
      required={required}
      margin={margin}
      disabled={disabled}>
      {!hideInputLabel && (
        <InputLabel htmlFor="multiple-select" id="select-label">
          {label}
        </InputLabel>
      )}
      <MuiSelect
        labelId="select-label"
        open={open}
        variant={variant}
        value={value}
        required={required}
        label={!hideInputLabel ? label : ""}
        placeholder={placeholder}
        onOpen={handleOpen}
        onChange={(event: any, child: any) => {
          if (child.props.disabled) {
            return;
          }
          onChange?.(event.target.value);
        }}
        className={classes.select}
        inputProps={{
          name: "multiple-select",
          id: "multiple-select",
          ...inputProps
        }}
        MenuProps={{
          classes: {
            paper: clsx(classes.selectMenu, {
              [classes.fixedMenuHeight]: fixedMenuHeight
            })
          },
          MenuListProps: { disablePadding: true },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          autoFocus: false,
          getContentAnchorEl: null,
          ...menuProps
        }}
        renderValue={(value: any) => <>{value}</>}
        onClose={() => {
          open && handleClose();
          setSearch("");
        }}
        {...restProps}>
        <MenuItemWithTooltip value={NO_TARGET_COL_TEXT}>{NO_TARGET_COL_TEXT}</MenuItemWithTooltip>
        <ListSubheader className={classes.subHeader}>{"Target Column"}</ListSubheader>
        <SelectSearch value={search} onChange={setSearch} />
        <FixedSizeList
          height={Math.min(250, filtered.length * 48)}
          itemCount={filtered.length}
          itemSize={48}
          width={400}>
          {renderRow}
        </FixedSizeList>
      </MuiSelect>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

interface MenuItemWithTooltipProps extends MenuItemProps {
  helpText?: string;
}

const MenuItemWithTooltip = React.forwardRef((props: MenuItemWithTooltipProps, ref) => {
  const { children, helpText, ...restProps } = props;
  return (
    <Tooltip title={helpText || ""} placement="bottom-start">
      <MenuItem {...restProps} innerRef={ref} button>
        {children}
      </MenuItem>
    </Tooltip>
  );
});
MenuItemWithTooltip.displayName = "MenuItemWithTooltip";

export default React.memo(TargetColumnSelect);
