import React, { useCallback, useState, useMemo } from "react";

// Packages
import { Link, useNavigate, useLocation } from "react-router-dom";
import shallow from "zustand/shallow";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { isEmpty, lowerCase, map, startCase, trim } from "lodash";

// MUI
import {
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  Button,
  TextField,
  CircularProgress,
  Typography
} from "@material-ui/core";

// Icons
import { Alert } from "@material-ui/lab";
import rapidCanvasLogo from "src/assets/images/rc-logo-dark-text.svg";

// Utils
import api from "src/utils/AxiosClient";
import { PatternRexExps } from "src/utils";

// Stores
import useAuthStore from "src/stores/auth.store";
import useNotificationStore from "src/stores/notification.store";
import { shouldRefreshProjectsToggler } from "src/store/store.selectors";
import { useProjectsStore } from "src/store/store";

// Hooks
import { useAuthAwaitStreamlit } from "src/hooks/useAuthAwaitStreamlit";
import { useAuthSetAuthResponse } from "src/hooks/useAuthSetAuthResponse";

// API Hooks
import useQuery from "src/hooks/useQuery";
import { UseGetDataConnectorsQueryKeys } from "src/hooks/api";
import { useGetRole } from "src/hooks/useGetRole";
import { fetchRedirectUrlPostSignIn, cleanLocationParams } from "src/utils/urlUtils";

// Components
import ModalAgreement from "src/components/ModalAgreement/ModalAgreement";
import PasswordInput from "src/components/PasswordInput";

// Constants
import { PagesViewConfiguration, PublicScreenLabels } from "src/constants";

// Styles
import { useStyles } from "./SignIn.styles";

const SignIn = () => {
  const classes = useStyles();

  // $FixMe: Scope to be refactored.
  // Old code - STARTS >>
  const navigate = useNavigate();
  const location = useLocation();

  const queryClient = useQueryClient();

  const { setAuthResponse } = useAuthSetAuthResponse();

  const [setToken, setTokenPermanent] = useAuthStore(
    useCallback((state) => [state.setToken, state.setTokenPermanent], []),
    shallow
  );
  const setNotification = useNotificationStore(
    useCallback((state) => state.setNotification, []),
    shallow
  );

  const { checkIsRoleYieldsDataAppView } = useGetRole();

  const queryParameters = useQuery();

  const [isEmailPasswordErrorVisible, setIsEmailPasswordErrorVisible] = useState(false);
  const [IsError500Visible, setIsError500Visible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [displayAgreement, setDisplayAgreement] = useState(false);

  const toggleShouldProjectsRefresh = useProjectsStore(shouldRefreshProjectsToggler);
  const { shouldFetchStreamlitToken, fetchAllTokens } = useAuthAwaitStreamlit({
    fetchOnLoad: false
  });

  const hasInviteTenantRequestParams = queryParameters.get("invitation_details");

  const {
    register,
    setValue,
    // watch,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues: { email: "", password: "" } });

  // const formValueEmail = watch("email");
  // const formValuePassword = watch("password");

  const clearBEErrors = () => {
    setIsEmailPasswordErrorVisible(false);
    setIsError500Visible(false);
    setErrorMessage(null);
  };

  const handleEmailPasswordSubmit = async (data: $TSFixMe) => {
    const invitationDetails = queryParameters.get("invitation_details");

    try {
      setIsSigningIn(true);

      const formData = new FormData();
      formData.append("email", data.email);
      formData.append("password", data.password);

      if (invitationDetails) {
        formData.append("invitation_details", invitationDetails);
      }

      const response = await axios.post("/api/login/authenticate", formData);

      if (response.status === 200) {
        handleAuthSuccessSteps(response, invitationDetails);
      }
    } catch (error: $TSFixMe) {
      if (error?.response?.status === 401) {
        setIsEmailPasswordErrorVisible(true);
      } else if (error?.response?.status >= 500) {
        setIsError500Visible(true);
      } else {
        setErrorMessage(error?.response?.data?.msg || error?.message);
      }

      setIsSigningIn(false);
    }
  };

  const handleAuthSuccessSteps = async (response: $TSFixMe, invitationDetails?: $TSFixMe) => {
    const { token, eulaAccepted, tenantName, roleName } = response.data;

    localStorage.setItem(PagesViewConfiguration, "{}");
    setToken(token);
    setTokenPermanent(token);
    api.init(token);
    if (shouldFetchStreamlitToken) {
      try {
        await fetchAllTokens();
      } catch {
        return;
      }
    }
    setAuthResponse({
      data: response.data,
      rememberMe: true
    });

    const decodedUrl = decodeURIComponent(window.location.href);
    const searchParams = new URL(decodedUrl).searchParams;
    const dataappurl = searchParams.get("dataappurl");
    if (dataappurl) {
      window.location.href = dataappurl;
    }

    setDisplayAgreement(!eulaAccepted);
    setIsSigningIn(false);
    toggleShouldProjectsRefresh();

    if (eulaAccepted) {
      // In case of streamlit token fetch failure, clean url params
      const updatedLocation = cleanLocationParams(location);
      invitationDetails &&
        setNotification({
          type: "Dashboard",
          message: `You are added to "${tenantName}" tenant successfully`
        });
      const { pathname, search } = fetchRedirectUrlPostSignIn(updatedLocation, token);
      // If an unauthenticated request originates from a certain route, Redirect to that route saved in the state variable
      const shouldRedirectToOriginalRoute = location?.state?.from?.pathname?.includes("/apps/");
      const updatePathName = shouldRedirectToOriginalRoute
        ? location?.state?.from?.pathname
        : pathname;
      const searchParams = shouldRedirectToOriginalRoute ? location?.state?.from?.search : search;

      // Get third-party connectors for roles that do not yield data-app view role.
      if (!!roleName && !checkIsRoleYieldsDataAppView(roleName)) {
        const dataConnectorsData = queryClient.getQueryData([
          UseGetDataConnectorsQueryKeys.Connectors
        ]);

        if (!dataConnectorsData) {
          queryClient.prefetchQuery({ queryKey: [UseGetDataConnectorsQueryKeys.Connectors] });
        }
      }

      navigate({ pathname: updatePathName, search: searchParams });
    }
  };

  const handleNavigate = (nav: $TSFixMe) => {
    setDisplayAgreement(false);
    if (nav) {
      navigate({ pathname: "/", search: location.search }, { state: location.state });
    }
  };

  const title = hasInviteTenantRequestParams ? "Join Tenant" : startCase(PublicScreenLabels.SignIn);
  // << ENDS - Old code

  const boldText = (text: string) => (
    <Typography variant="inherit" style={{ fontWeight: 500 }}>
      {text}
    </Typography>
  );

  // Not using this flag for now, as it considers empty fields even fields are auto-filled by browser.
  // $FixMe: Scope to be refactored.
  // Can be refactored with a better approach from react-hook-form.
  // const isAllFieldsEmpty = useMemo(
  //   () => !(!!formValueEmail && !!formValuePassword),
  //   [formValueEmail, formValuePassword]
  // );

  const emailIdError = useMemo(() => {
    const errorsList = [];

    if (errors?.email?.type === "required") {
      errorsList.push(`${startCase(PublicScreenLabels.Email)} is required!`);
    }

    if (errors?.email?.type === "pattern") {
      errorsList.push(`${startCase(PublicScreenLabels.Email)} format invalid!`);
    }

    if (!isEmpty(errorsList)) {
      return (
        <List dense disablePadding>
          {map(errorsList, (error: string | $TSFixMe, index: number) => (
            <ListItem key={`emailError-${index}`} disableGutters>
              <ListItemText primary={error} disableTypography style={{ marginTop: 0 }} />
            </ListItem>
          ))}
        </List>
      );
    }

    return "";
  }, [errors?.email?.type]);

  const passwordError = useMemo(() => {
    const errorsList = [];

    if (errors?.password?.type === "required") {
      errorsList.push(`${startCase(PublicScreenLabels.Password)} is required!`);
    }

    !!IsError500Visible && errorsList.push("Oops, something went wrong! Please try again.");
    !!errorMessage && errorsList.push(errorMessage);

    if (!isEmpty(errorsList)) {
      return (
        <List dense disablePadding>
          {map(errorsList, (error: string | $TSFixMe, index: number) => (
            <ListItem key={`passwordError-${index}`} disableGutters>
              <ListItemText primary={error} disableTypography style={{ marginTop: 0 }} />
            </ListItem>
          ))}
        </List>
      );
    }

    return "";
  }, [errors?.password?.type, IsError500Visible, errorMessage]);

  const signInError = useMemo(() => {
    const errorsList: $TSFixMe[] = [];

    !!isEmailPasswordErrorVisible &&
      errorsList.push(
        `${startCase(PublicScreenLabels.Email)} or ${startCase(
          PublicScreenLabels.Password
        )} is invalid!`
      );

    if (!isEmpty(errorsList)) {
      return (
        <List dense disablePadding>
          {map(errorsList, (error: string | $TSFixMe, index: number) => (
            <ListItem key={`signInError-${index}`} disableGutters>
              <ListItemText primary={error} disableTypography style={{ margin: 0 }} />
            </ListItem>
          ))}
        </List>
      );
    }

    return "";
  }, [isEmailPasswordErrorVisible]);

  return (
    <>
      {!!displayAgreement && <ModalAgreement onClose={handleNavigate} />}

      <Grid container className={classes.page}>
        <Paper variant="outlined" className={classes.root}>
          <Grid container direction="column" className={classes.container}>
            <Grid item className={classes.rapidCanvasLogoContainer}>
              <img
                src={rapidCanvasLogo}
                width={250}
                alt="RapidCanvas logo"
                data-testid="signInRapidCanvasLogo"
              />
            </Grid>
            <Grid item className={classes.titleContainer}>
              <Typography variant="h5" component="div" align="center" data-testid="signInTitle">
                {title}
              </Typography>
            </Grid>
            <Grid item className={classes.messageContainer}>
              <Typography
                variant={!!hasInviteTenantRequestParams ? "subtitle1" : "h6"}
                component="div"
                align="center"
                style={{ fontWeight: 400 }}
                data-testid="signInMessageLine1">
                {!!hasInviteTenantRequestParams ? (
                  <>
                    To join the tenant, {lowerCase(PublicScreenLabels.SignIn)} to{" "}
                    {boldText("RapidCanvas")}
                  </>
                ) : (
                  <>Welcome to {boldText("RapidCanvas")}</>
                )}
              </Typography>
            </Grid>

            <Grid
              container
              component="form"
              className={classes.formContainer}
              onChange={clearBEErrors}
              onSubmit={handleSubmit(handleEmailPasswordSubmit)}>
              <TextField
                id="signInEmail"
                data-testid="signInEmail"
                label={startCase(PublicScreenLabels.Email)}
                variant="outlined"
                size="small"
                color="primary"
                fullWidth
                // eslint-disable-next-line
                autoFocus
                autoComplete="off"
                disabled={!!isSigningIn}
                {...register("email", {
                  required: true,
                  pattern: PatternRexExps.TrimmedEmail,
                  onBlur: (event) => {
                    setValue("email", trim(event?.target?.value), { shouldValidate: true });
                  }
                })}
                {...(!!emailIdError
                  ? {
                      error: true,
                      helperText: emailIdError
                    }
                  : {})}
              />
              <PasswordInput
                // @ts-ignore
                id="signInPassword"
                data-testid="signInPassword"
                variant="outlined"
                size="small"
                color="primary"
                fullWidth
                autoComplete="off"
                disabled={!!isSigningIn}
                {...register("password", { required: true })}
                {...(!!passwordError
                  ? {
                      error: true,
                      helperText: passwordError
                    }
                  : {})}
              />

              <Typography variant="body2" data-testid="signInForgotPasswordMessage">
                Forgot Password?{" "}
                <Link
                  id="signInForgotPasswordLink"
                  data-testid="signInForgotPasswordLink"
                  to="/auth/reset-password-link-send"
                  className={classes.link}>
                  Get help
                </Link>
              </Typography>

              <Button
                id="signInAction"
                data-testid="signInAction"
                type="submit"
                variant="contained"
                color="primary"
                size="small"
                fullWidth
                className={classes.action}
                // disabled={!!isSigningIn || isAllFieldsEmpty}
                disabled={!!isSigningIn}>
                {!!isSigningIn ? (
                  <>
                    <CircularProgress size={16} style={{ marginRight: 5 }} />
                    Signing in...
                  </>
                ) : (
                  startCase(PublicScreenLabels.SignIn)
                )}
              </Button>

              {!!signInError && (
                <Alert
                  variant="outlined"
                  severity="error"
                  className={classes.errorContainer}
                  data-testid="signInErrorContainer">
                  <Typography variant="caption">{signInError}</Typography>
                </Alert>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};

export default SignIn;
