/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { Condition } from './condition';
// May contain unused imports in some cases
// @ts-ignore
import { DFSRunConfigDto } from './dfsrun-config-dto';
// May contain unused imports in some cases
// @ts-ignore
import { EntityViewDTO } from './entity-view-dto';
// May contain unused imports in some cases
// @ts-ignore
import { PipelineStep } from './pipeline-step';

/**
 * 
 * @export
 * @interface DFSRunConfigGroupDto
 */
export interface DFSRunConfigGroupDto {
    /**
     * 
     * @type {string}
     * @memberof DFSRunConfigGroupDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DFSRunConfigGroupDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DFSRunConfigGroupDto
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DFSRunConfigGroupDto
     */
    'projectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DFSRunConfigGroupDto
     */
    'status'?: DFSRunConfigGroupDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof DFSRunConfigGroupDto
     */
    'errorMessage'?: string;
    /**
     * 
     * @type {Array<DFSRunConfigDto>}
     * @memberof DFSRunConfigGroupDto
     */
    'runConfigs'?: Array<DFSRunConfigDto>;
    /**
     * 
     * @type {Array<PipelineStep>}
     * @memberof DFSRunConfigGroupDto
     */
    'children'?: Array<PipelineStep>;
    /**
     * 
     * @type {Array<PipelineStep>}
     * @memberof DFSRunConfigGroupDto
     */
    'parents'?: Array<PipelineStep>;
    /**
     * 
     * @type {Array<EntityViewDTO>}
     * @memberof DFSRunConfigGroupDto
     */
    'childrenDetails'?: Array<EntityViewDTO>;
    /**
     * 
     * @type {Condition}
     * @memberof DFSRunConfigGroupDto
     */
    'condition'?: Condition;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof DFSRunConfigGroupDto
     */
    'metadata'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof DFSRunConfigGroupDto
     */
    'envId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DFSRunConfigGroupDto
     */
    'envName'?: string;
    /**
     * 
     * @type {number}
     * @memberof DFSRunConfigGroupDto
     */
    'timeout'?: number;
    /**
     * 
     * @type {string}
     * @memberof DFSRunConfigGroupDto
     */
    'markdown'?: string;
    /**
     * 
     * @type {string}
     * @memberof DFSRunConfigGroupDto
     */
    'recipeType'?: DFSRunConfigGroupDtoRecipeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DFSRunConfigGroupDto
     */
    'icon'?: string;
    /**
     * 
     * @type {string}
     * @memberof DFSRunConfigGroupDto
     */
    'image'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DFSRunConfigGroupDto
     */
    'newAskAIFlow'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DFSRunConfigGroupDto
     */
    'codeChanged'?: boolean;
}

export const DFSRunConfigGroupDtoStatusEnum = {
    Pending: 'PENDING',
    Built: 'BUILT',
    Empty: 'EMPTY',
    Running: 'RUNNING',
    Stopped: 'STOPPED',
    Success: 'SUCCESS',
    Error: 'ERROR',
    Unbuilt: 'UNBUILT',
    Skipped: 'SKIPPED',
    Building: 'BUILDING'
} as const;

export type DFSRunConfigGroupDtoStatusEnum = typeof DFSRunConfigGroupDtoStatusEnum[keyof typeof DFSRunConfigGroupDtoStatusEnum];
export const DFSRunConfigGroupDtoRecipeTypeEnum = {
    Standard: 'STANDARD',
    Code: 'CODE',
    AutoMl: 'AUTO_ML',
    ApiConnector: 'API_CONNECTOR'
} as const;

export type DFSRunConfigGroupDtoRecipeTypeEnum = typeof DFSRunConfigGroupDtoRecipeTypeEnum[keyof typeof DFSRunConfigGroupDtoRecipeTypeEnum];


